<template>
  <div class="main-content">
    <h3 class="marginBottom20">详情-新房</h3>
    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption">
      <template slot="titleOne">
        <h3>带看信息</h3>
      </template>
      <template slot="id"><div>{{ form.id }}</div></template>
      <template slot="username"><div>{{ form.username }}</div></template>
      <template slot="phone"><div>{{ form.phone }}</div></template>
      <template slot="subscribeTime"><div>{{ form.subscribeTime || '/' }}</div></template>
      <template slot="remark"><div>{{ form.remark || '/' }}</div></template>
      <template slot="examineState"><div>{{ form.examineState === 1 ? '待审核' : form.examineState === 2 ? '审核通过' : form.examineState === 3 ? '审核驳回' : '' }}</div></template>
      <template slot="rejectReason"><div>{{ form.rejectReason || '/' }}</div></template>
      <template slot="lookState">
        <div v-if="form.examineState === 2">{{ form.lookState === 1 ? '待看' : form.lookState === 2 ? '已带看' : form.lookState === 3 ? '已取消' : '/' }}</div>
        <div v-else>/</div>
      </template>
      <template slot="titleTwo">
        <h3>房源信息</h3>
      </template>
      <template slot="title"><div>{{ form.title }}</div></template>
      <template slot="city"><div>{{ form.city }}{{ form.county }}</div></template>
      <template slot="business"><div>{{ form.business }}</div></template>
      <template slot="name"><div>{{ form.name }}</div></template>
      <template slot="averagePrice"><div>{{ form.averagePrice }} 元/m²</div></template>
      <template slot="openTime"><div>{{ form.openTime }}</div></template>
      <template slot="propertyRight"><div>{{ form.propertyRight }} 年</div></template>
      <template slot="labelNames"><div>{{ form.label }}</div></template>
      <template slot="array">
        <avue-crud
            :data="form.intros"
            :option="option"
        >
        </avue-crud>
      </template>
      <template slot="menuForm">
        <el-button style="padding: 9px 40px" @click="handleBack">返 回</el-button>
      </template>
    </avue-form>
  </div>
</template>

<script>
import { detail } from '@/api/house/look'
export default {
  name: "detail",
  data(){
    return{
      loading: false,
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        disabled: true,
        column: [
          {
            label: '',
            prop: 'titleOne',
            labelWidth: 100,
            span: 24
          },
          {
            label: '新房看房ID',
            prop: 'id',
            span: 24
          },
          {
            label: '客户信息',
            prop: 'username',
            span: 24
          },
          {
            label: '客户手机号',
            prop: 'phone',
            span: 24
          },
          {
            label: '预约时间',
            prop: 'subscribeTime',
            span: 24
          },
          {
            label: '需求描述',
            prop: 'remark',
            span: 24
          },
          {
            label: '审核状态',
            prop: 'examineState',
            span: 24
          },
          {
            label: '驳回原因',
            prop: 'rejectReason',
            span: 24
          },
          {
            label: '带看状态',
            prop: 'lookState',
            span: 24
          },
          {
            label: '',
            prop: 'titleTwo',
            labelWidth: 100,
            span: 24
          },
          {
            label: '房源标题',
            prop: 'title',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '城市',
            prop: 'city',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '商圈',
            prop: 'business',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '楼盘名称',
            prop: 'name',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '参考均价',
            prop: 'averagePrice',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '开盘时间',
            prop: 'openTime',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '产权',
            prop: 'propertyRight',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '标签',
            prop: 'labelNames',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '房源图片上传',
            prop: 'images',
            type: 'upload',
            span: 24,
            dataType: 'string',
            listType: 'picture-card',
            rules: [{ required: true }]
          },
          {
            label: '户型介绍',
            prop: 'array',
            span: 24,
            rules: [{ required: true }]
          }
        ]
      },
      option: {
        title: '',
        titleSize: 'h3',
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号',
        indexWidth: 100,
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menu: false,
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '户型图片',
            prop: 'image',
            type: 'img'
          },
          {
            label: '户型',
            prop: 'bedroom',
            formatter: (row, column, cellValue) => {
              return `${cellValue} 室 ${row.office} 厅 ${row.toilet} 卫`
            }
          },
          {
            label: '建面(m²)',
            prop: 'floorage'
          },
          {
            label: '期望售价(万元)',
            prop: 'sellPrice'
          }
        ]
      }
    }
  },
  async mounted(){
    this.loading = true
    await this.getDetail()
  },
  methods: {
    async getDetail(){
      const { data } = await detail({id: this.$route.query.id})
      const { labelNames, ...rest } = data;
      const label = labelNames.join('、');
      this.form = {
        ...rest,
        label
      };
    this.loading = false
    },
    handleBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>